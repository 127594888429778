import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import DefaultLayout from "layouts/Default";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import HeroSlider from "components/blocks/HeroSlider";
import Link from "next/link";
import {Button} from "@mui/material";
import ItemSlider from "components/blocks/ItemSlider";
import LocationCard from "components/cards/LocationCard";
import BrandCard from "components/cards/BrandCard";
import ProductCard from "components/cards/ProductCard";
import ArticleCard from "components/cards/ArticleCard";
import {getArticles} from "lib/services/article";
import {wrapper} from "lib/store";
import {getTopBrandsThunk} from "lib/features/brandSlice";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import services from "./brands/[brandSlug]/[id]/services";
import {checkMobile} from '../utils/helper'
import {getTopProductsThunk} from "lib/features/productSlice";
import {getTopLocationsThunk} from "lib/features/mapSlice";

import Head from 'next/head'

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
  const {
    locale
  } = context
    await store.dispatch(getTopBrandsThunk({num:12}))
    await store.dispatch(getTopProductsThunk({num:12}))
    // await store.dispatch(getTopServicesThunk(12))
    await store.dispatch(getTopLocationsThunk({num:20}))

    // const stores = await getFeaturedLocations()
  const learnArticles = (await getArticles({type: 'learns'})).slice(0, 9)
  const newsArticles = (await getArticles({type: 'news'})).slice(0, 9)

  return {
    props: {
      learnArticles,
      newsArticles,
        ...(await serverSideTranslations(locale, ['home', 'common']))
    }
  }
})

export default function Home(props) {
  const {
    stores,
    learnArticles,
    newsArticles,
      locale
  } = props

    const { t, i18n } = useTranslation()
    let topBrands = useSelector(state => state.brand.topBrands)
    let topProducts = useSelector(state => state.product.topProducts)
    let topServices = useSelector(state => state.product.topServices)
    let topLocations = useSelector(state => state.map.topLocations)

    const [localProducts, setLocalProducts] = useState([])
    const [localBrands, setLocalBrands] = useState([])
    const [localStores, setLocalStores] = useState([])

    const [loaded, setLoaded] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (loaded) {
            setIsMobile(checkMobile)
        }
    }, [loaded])
    useEffect(() => {
        if (!!topBrands && topBrands.length > 0 && !!topProducts && topProducts.length > 0 && !!topLocations && topLocations.length > 0) {
            setLoaded(true)
        }
        if (!!topBrands && topBrands.length > 0) {

            let temp = [...topBrands]
            let checkGrowTown = temp.find(item => item.id === '139')
            let checkCabana = temp.find(item => item.id === '142')
            let checkTokyo = temp.find(item => item.id === '143')
            let growTown =  {
                id: '139',
                description: 'Welcome to Growtown! Our mission is to bring unique products and ideas to the cannabis community. Whether we are blazing the trail with innovative never-before-seen products, or evolving your favourite legacy strain, it is our goal to curate a spectrum of accessible, quality products stemming from research, development, and community feedback. \n' +
                    '\n' +
                    '​\n' +
                    '\n' +
                    'Through the celebration of major and minor cannabinoids and the excitement of exploring uncharted territories, we are committed to being more than just another brand of cannabis.',
                name: 'GrowTown',
                create_dt: '2023-03-16 02:35:57',
                social_link: '[{"platform":"Instagram","accountId":"growtowncannabis"}]',
                category: '',
                biz_type: 'PRODUCT',
                logo: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/7C0tobknlcIgFUUi9hkmV-Growtown%20Logo%20no%20Circle%20(3).png',
                hero: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/wt-PwiwInH9UZEt4DlHd7-51821678934100_.pic.jpg',
                profile_id: '49',
                sts: 'ACTIVE',
                public_viewable: 'N',
                promo_rank: '1',
                slug: 'GrowTown',
                bizType: 'PRODUCT',
                businessType: undefined,
                products: '',
                services: '',
                locations: '',
                liked: undefined,
                followers: undefined,
                deals: undefined,
                productsList: undefined,
                serviceList: undefined
            }
            let cabana =  {
                __typename: 'Brand',
                id: '142',
                name: 'Canna Cabana',
                slug: 'Canna Cabana',
                description: 'Welcome to the blue skies and sunny shores of Canna Cabana. When we say we’re the real OGs, we mean it—for over 10 years, we’ve been smoking and toking and creating places for cannabis lovers of all kinds to gather, chat, and share their passion for the plant.\n' +
                    '\n' +
                    'We began as Smoker’s Corner in Calgary, 2009. Back then, we were just a little head shop with big dreams of changing cannabis culture for the better. We knew that smokers deserved more than sketchy alleyways and dim basements. Stoner culture is vibrant, fun, and full of amazing people—and so we created a place that reflected us.\n' +
                    '\n' +
                    'When legalization finally came, we wanted to bring that same vibe to selling cannabis (Man, sometimes we still can’t believe it’s finally legal. Someone pinch us?). Canna Cabana was created by and for people who love weed—love to smoke it, buy it, chat about it, and share it with their friends.\n' +
                    '\n' +
                    'Want to check out a new vape, discover a new strain, get some advice from our experts, or stock up for a hazy weekend? Step on in to your local Canna Cabana–we built it for you!',
                businessType: 'STORE',
                category: '',
                logo: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/28qvMCz8XxFNnjcs1Bt-v-cannacabana-logo-color-01.png',
                hero: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/ZPqWkcBI7rHcLgAnDsHy3-lvlkUgnAQOSeoQSvJeXp_Canna-cabana-CP.avif',
                publicViewable: true,
                locations: '',
                products: '',
                services: '',
                liked: false,
                followers: 951,
                bizType: 'LOCATION'
            }
            let tokyoSmoke =  {
                __typename: 'Brand',
                id: '143',
                name: 'Tokyo Smoke',
                slug: 'Tokyo Smoke',
                description: 'Tokyo Smoke is an award-winning, design focused, legal cannabis retailer. We believe that cannabis is good, and that your shopping experience should be nothing short of great.',
                businessType: 'PRODUCT',
                category: '',
                logo: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/bG45bXCupaCa92wShfv6k-Tokyo%20Smoke.jpg',
                hero: 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/lx-yrgU1Ik9sXXdeTKsm1-61571692586161_.pic.jpg',
                publicViewable: false,
                locations: '',
                products: '',
                services: '',
                liked: false,
                followers: 191,
                bizType: 'LOCATION'
            }
            /** Hard fix brands missing **/
            if (!!checkGrowTown && checkGrowTown.name === '') {
                temp.splice(0, 1, growTown)
            }

            if (!!checkCabana && checkCabana.name === '') {
                temp.splice(1, 1, cabana)
            }

            if (!!checkTokyo && checkTokyo.name === '') {
                temp.splice(2, 1, tokyoSmoke)
            }
            setLocalBrands(temp)

        }
        if (!!topProducts && topProducts.length > 0) {
            setLocalProducts(topProducts)
        }
        if (!!topLocations && topLocations.length > 0) {
            setLocalStores(topLocations)
        }



    }, [topBrands, topProducts, topLocations, loaded])

    useEffect(() => {
        // console.log('here are products: ', JSON.stringify(localProducts.slice(0, 3)))
        // console.log('here are services: ', JSON.stringify(services.slice(services.length - 3, services.length)))
        //
        // console.log('here are brands: ', JSON.stringify(localBrands.slice(0, 3)))
        // console.log('here are stores: ', JSON.stringify(localStores.slice(0, 3)))
    }, [localStores, localProducts, localBrands, services])

    useEffect(() => {
        console.log('topBrands: ', topBrands)
    }, [topBrands])

  const HeroSlides = [
    {
      image: require("assets/hero-img-1-2.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "text-6xl font-serif text-white mb-7" : "text-8xl font-serif text-white mb-7"}>{t('home:findOut')}</h1>
          <h2 className="text-3xl font-semibold text-white mb-14">{t('home:whatWeCanDo')}</h2>
          <Link href="https://damamap.com/6-benefits-of-cbd-oil/" passHref>
            <a target="_blank" rel="noopener noreferrer">
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }
              >
                  {t('home:hero_learn_more')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }, {
      image: require("assets/hero-img-1-3.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "uppercase text-6xl font-serif text-white mb-7" : "uppercase text-7xl font-serif text-white mb-7"}>{t('home:trusted')}</h1>
          <h2 className="uppercase text-3xl font-semibold text-white mb-14">{t('home:consultant')}</h2>
          <Link href="https://damamaps.com/services/listing/Cannabis License Experts/73">
            <a>
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }>
                  {t('home:getYourLicense')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }, {
      image: require("assets/hero-img-1-4.png"),
      content: (
        <div className="drop-shadow-sm flex flex-col justify-center items-start h-full">
          <h1 className={isMobile ? "leading-tight text-4xl font-serif text-white mb-7" : "leading-tight text-5xl font-serif text-white mb-7"}>
            <span className={isMobile ? "text-4xl" : "text-6xl"}>{t('home:grow')}</span><br/>
              {t('home:yourBusiness')}<br/>
              {t('home:withUs')}
          </h1>
          <h2 className=" text-3xl font-semibold text-white mb-14">{t('home:listStore')}</h2>
          <Link href="https://damamap.com/en/list-your-stores/" passHref>
              <a target="_blank" rel="noopener noreferrer">
              <Button
                variant={'contained'} color={'black'} size={'xl'}
                className="normal-case"
                endIcon={
                  <FontAwesomeIcon icon={solid('chevron-right')} className='!h-3.5'/>
                }>
                  {t('home:hero_learn_more')}
              </Button>
            </a>
          </Link>
        </div>
      )
    }
  ]

  return (
    <>
        <Head>
            <title>DaMaMaps | Home</title>
            <meta name="keywords" content="DaMaMaps, DaMawish, CBD, THC" />
            <meta property="og:title" lang="en" content="DaMaMaps | Home" />
            <meta property="og:title" lang="zh-Hans"content="大麻地图 | 带你探寻一个神秘领域的生活方式" />
            <meta property="og:image" content="https://damawish-files-bucket-dev.s3.amazonaws.com/DaMaMaps_logo_transparent.png" />
            <meta property="og:description" lang="en" content="DaMaMaps.com is a cutting-edge cannabis technology and advertising company located in Toronto, Canada. As the first and foremost legal cannabis-related platform, we provide a comprehensive international marketplace, e-commerce services, and online social networking and communication platforms. Our website is currently available in English and Chinese versions, and we are now working towards launching versions in Japanese and Korean to broaden our global reach." />
            <meta property="og:description" lang="zh-Hans" content="大麻地图DaMaMaps.com是全球华人No.1最大的大麻媒体社交电商平台科技公司，位于加拿大安大略省多伦多市。我们为华人社区提供在线合法大麻平台，为用户提供查看，评价和讨论所有与大麻产品有关的商家, 当地药房与相关信息。我们的目标是在我们的平台上以提供高质量的文章与相应研究，尤其是华人社区，让大众客观了解大麻与汉麻。此外，我们有一个强大的中文社交网络平台，客户可以利用该平台进行交流，与其他用户互动并分享他们的见解。" />
            <meta property="og:url" content="https://damamaps.com" />
            <meta name="twitter:card" content="https://damawish-files-bucket-dev.s3.amazonaws.com/DaMaMaps_logo_transparent.png" />
        </Head>
      <HeroSlider
        id='home-hero-slider' className="!h-[40vh] max-h-[400px]"
        slides={HeroSlides} brightness={0.9} loop={true}
      />
      <Wrapper>
        <Container>
            {
                loaded ?
                    <ItemSlider
                        title={t('home:home_featuredstores')}
                        items={localStores}
                        href="/map"
                        render={item => (
                            <LocationCard data={item} t={t} i18n={i18n}/>
                        )}
                    /> :
                    <span>{t('home:loading')}</span>
            }
        </Container>
      </Wrapper>
        <Wrapper>
            <Container>
                {
                    loaded ?
                        <ItemSlider
                            title={t('home:home_featuredbrands')}
                            items={localBrands}
                            href="/brands/discovery"
                            render={item => (
                                <BrandCard data={item}/>
                            )}
                        /> :
                        <span>{t('home:loading')}</span>
                }
            </Container>
        </Wrapper>
        <Wrapper>
            <Container>
                {
                    loaded ?
                        <ItemSlider
                            title={t('home:home_featuredproducts')}
                            items={localProducts}
                            href="/products"
                            render={item => (
                                <ProductCard data={item} type={'Product'}/>
                            )}
                        /> :
                        <span>{t('home:loading')}</span>
                }
            </Container>
        </Wrapper>
      <Wrapper className="pt-6">
        <Container>
          <h2 className="text-2xl font-bold mb-6">
              {t('home:home_newsandeducation')}
          </h2>
          <ItemSlider
            title={t('home:learn_cannabis101')}
            items={learnArticles}
            href="https://damamap.com/law/"
            render={item => (
              <ArticleCard data={item}/>
            )}
          />
        </Container>
        <Container className="mt-16">
          <ItemSlider
            title={t('home:news')}
            items={newsArticles}
            href="https://damamap.com/news/"
            render={item => (
              <ArticleCard data={item}/>
            )}
          />
        </Container>
      </Wrapper>
      {/*<div><h1>hello</h1></div>*/}
    </>
  )
}

Home.getLayout = function getLayout(page) {
  return (
    <DefaultLayout>
      {page}
    </DefaultLayout>
  )
}
