import BrandHeader from "components/brands/BrandHeader";
import Container from "components/blocks/Container";
import Wrapper from "components/blocks/Wrapper";
import Link from "next/link";
import cn from "clsx";
import {useRouter} from "next/router";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {wrapper} from "lib/store";
import {getBrand} from "lib/features/brandSlice";
import {useTranslation} from "next-i18next";
import {checkMobile} from "../utils/helper";

export const getServerSideProps = wrapper.getServerSideProps(store => async ({query}) => {
    const {
        page = 1,
    } = query

    return {
        props: {
            page
        }
    }
})


const Tab = ({href, active, children, className}) => {
    return (
        <Link href={href}>
            <a>
                <div
                    className={cn(
                        'border-t-3 border-b-3 border-transparent py-3 px-5 -m-[1px]',
                        {'border-b-primary font-bold': active},
                        className
                    )}
                >
                    {children}
                </div>
            </a>
        </Link>
    )

}

export default function BrandLayout({children}) {
    const router = useRouter()
    const slug = router.query.brandSlug
    const id = router.query.id
    const { t } = useTranslation()

    const [loaded, setLoaded] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        if (loaded) {
            setIsMobile(checkMobile)
        }
    }, [loaded])

    return (
        <div>
            <BrandHeader />
            <Wrapper className={'py-0 border-t border-b'}>
                <Container className={isMobile ? 'flex overflow-scroll' : 'flex'}>
                    <Tab href={`/brands/${slug}/${id}`}
                         active={router.pathname === '/brands/[slug]/[id]'}
                    >
                        {t('home:brandsDiscovery')}
                    </Tab>
                    <Tab href={`/brands/${slug}/${id}/products`}
                         active={router.pathname === '/brands/[brandSlug]/[id]/products'}
                    >
                        {t('home:brandsFeaturedProducts')}
                    </Tab>
                    <Tab href={`/brands/${slug}/${id}/services`}
                         active={router.pathname === '/brands/[brandSlug]/[id]/services'}
                    >
                        {t('home:brandsFeaturedServices')}
                    </Tab>
                    <Tab href={`/brands/${slug}/${id}/stores`}
                         active={router.pathname === '/brands/[brandSlug]/[id]/stores'}
                    >
                        {t('home:brandsFeaturedStores')}
                    </Tab>
                    {/*<Tab href={`/brands/${slug}/feed`}*/}
                    {/*     active={router.pathname === '/brands/[brandSlug]/feed'}>*/}
                    {/*    Feed*/}
                    {/*</Tab>*/}
                </Container>
            </Wrapper>
            {children}
        </div>
    )
}
