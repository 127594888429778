import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';

export default function MyInput({inputName, placeHolder, value, onChange, handleClick, margin, onKeyDown }) {
    return (
        <FormControl style={{ marginTop: margin ? 10 : null }} sx={{ width: '25ch' }} variant="outlined">
            <InputLabel size='small' style={{ fontFamily: 'Montserrat', fontWeight: 500 }} htmlFor={inputName}>{placeHolder}</InputLabel>
            <OutlinedInput
                size='small'
                id={inputName}
                type={'text'}
                value={value}
                onChange={onChange}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        // Do code here
                        handleClick()
                        ev.preventDefault();
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClick}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                label={placeHolder}
            />
        </FormControl>
    );
}
