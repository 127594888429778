import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import ImageWithFallback from "components/ImageWithFallback";
import React, {useState} from "react";
import MyBreadcrumbs from "components/MyBreadcrumbs";
import {useSelector} from "react-redux";
import Rating from "components/Rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function BrandHeader() {
    const brand = useSelector(state => state.brand.brand)

    // console.log('at header: ', brand)

    const BREAD_ROUTE = [
        {label: 'Home', href: '/', isActive: false},
        {label: 'Brands', href: '/brands/discovery', isActive: false},
        {label: brand.name, href: `/brands/${brand.slug}`, isActive: true},
    ]

    return (
        <Wrapper className={'p-0 relative'}>
            <ImageWithFallback className={'absolute inset-0 object-cover h-400'} src={brand.hero} alt={brand.name}/>
            <Container className={'relative z-1 w-[86.667%] pt-10'}>
                <MyBreadcrumbs  routeList={BREAD_ROUTE} inverted={true}/>
                <div className="flex flex-row items-center mt-5">
                    <div className="relative w-[100px] after:content-[''] after:block after:pb-[100%] mr-5">
                        <ImageWithFallback
                            src={brand.logo}
                            alt={brand.name}
                            className={'object-contain'}
                            fallback={{size: '640x1:1'}}
                        />
                    </div>
                    <div className="flex-column ">
                        <div className="flex flex-row items-center text-white justify-center">
                            <h1 className={"text-2xl font-bold mb-2 text-white"}>
                                {brand.name}
                            </h1>
                            <div className="mb-2 w-10 mr-1 text-center text-blue-400 self-auto justify-self-center	">
                                <FontAwesomeIcon icon={solid('badge')} />
                            </div>
                        </div>
                        <div className="flex flex-nowrap items-center mb-4 text-white">
                            <FavoriteIcon className={"mr-1"} sx={{ width: 20 }}/>
                            <div className="text-xs mr-2 font-medium">
                                {`${brand.followers} followers`}
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        </Wrapper>

    )
}
