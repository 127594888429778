import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";

import React, {useEffect, useState} from "react";
import Link from 'next/link'
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import {Button} from '@mui/material'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useTranslation} from "next-i18next";
import { checkMobile } from '../../utils/helper'

const Pagination = () => {
  const swiper = useSwiper()

  const [isBeginning, setIsBeginning] = useState(swiper.isBeginning)
  const [isEnd, setIsEnd] = useState(swiper.isEnd)

  swiper.on('slideChange', () => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  })

    const { t } = useTranslation()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        setIsMobile(checkMobile())
    }, [])


  return (
    <div className={isMobile ? "flex justify-end mt-7" : "flex justify-end mt-5"}>
      <Button variant={'text'} size={'medium'} disabled={isBeginning}
              className="min-w-0 mr-2 text-black hover:text-primary"
              onClick={() => swiper.slidePrev()}>
        <FontAwesomeIcon icon={solid('chevron-left')}/>
      </Button>
      <Button variant={'text'} size={'medium'} disabled={isEnd}
              className="min-w-0 text-black hover:text-primary"
              onClick={() => swiper.slideNext()}>
        <FontAwesomeIcon icon={solid('chevron-right')}/>
      </Button>
    </div>
  )
}

export default function ItemSlider(
  {
    title,
    items,
    href,
    render,
    breakpoints={
      700: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    }
  }
) {
    const { t } = useTranslation()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        setIsMobile(checkMobile())
    }, [])

    return (
    <>
      <div className="flex items-center mb-4">
        <div className="font-semibold grow text-lg">
          {title}
        </div>
        {
          href && <Link href={href}>
            <a>{t('home:button_showall')}</a>
          </Link>
        }
      </div>
      <Swiper
        loop={false}
        slidesPerView={isMobile ? 1 : 4}
        slidesPerGroup={isMobile ? 1 : 4}
        spaceBetween={15}
        autoHeight
        breakpoints={breakpoints}
      >
        {
          items.map((item, i) => (
            <SwiperSlide key={i}>
              {
                render(item)
              }
            </SwiperSlide>
          ))
        }
        <Pagination slot="container-end"/>
      </Swiper>
    </>
  )
}
