import React, {useEffect, useRef, useState} from "react";
import Link from '@mui/material/Link';
import {MenuItem, Select} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Wrapper from "components/blocks/Wrapper";
import MyInput from "components/MyInput";
import { checkMobile } from '../utils/helper'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        padding: 0
    },
    table: {
        "&:last-child": {
            // backgroundColor: 'red',
            alignSelf: "flex-end !important",
            justifyContent: 'flex-end',
            display: 'flex'
        },
    }
}));

export default function MyList(props) {
    const classes = useStyles();
    const ref = useRef();
    const {
        sort, setSort, SORT_OPTIONS, dataList, title, subTitle, name, showAll, showSearchAndSort, itemComponent: ItemComponent, baseUrl, t
    } = props

    const [searchInput, setSearchInput] = useState('')
    const [localList, setLocalList] = useState(dataList)
    const [isMobile, setIsMobile] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const onChange = (e) => {
        setSearchInput(e.target.value)
    }

    const onClickFind = () => {
        let res = dataList.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()))
        console.log('searching! : ', searchInput, res, res.length)
        setLocalList(res)
    }

    const handleSortChange = (event) => {
        setSort(event.target.value)
    }

    useEffect(() => {
        setLocalList(dataList)
        setLoaded(true)
    }, [dataList])

    useEffect(() => {
        if (loaded) {
            setIsMobile(checkMobile())
        }
    }, [loaded])

    return (
        <Wrapper>
            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <h1 style={{ cursor: 'pointer' , fontSize: 14, fontWeight: 700, textDecoration: 'unset' }}>{title}</h1>

                {
                    showAll ?
                        <Link href={`/brands/${name}`} style={{ alignSelf: 'flex-end', display: 'flex', textDecoration: 'none' }}>
                            <h3 style={{ fontSize: 14, fontWeight: 400, color: '#7C7C7C', fontFamily: 'Montserrat' }}>{!!t ? t('button_showall') : 'Show All'}</h3>
                        </Link> :
                        subTitle === undefined ?
                            null : <h3 style={{ cursor: 'pointer', fontSize: 14, fontWeight: 400, color: '#7C7C7C', fontFamily: 'Montserrat' }}>{subTitle}</h3>
                }
                {
                    showSearchAndSort ?
                        <div className="w-full h-full flex flex-row items-center justify-between">
                            <Select
                                value={sort}
                                onChange={handleSortChange}
                                size={'small'} className={"w-52 text-sm bg-white"}
                                defaultValue={!!t? t(`home:${SORT_OPTIONS[0].label}`) : SORT_OPTIONS[0].label}
                            >
                                {
                                    SORT_OPTIONS.map(option => (
                                        <MenuItem key={option.label} value={option}>{!!t ? t(`home:${option.label}`) : option.label}</MenuItem>
                                    ))
                                }
                            </Select>
                            <MyInput onChange={onChange} value={searchInput} handleClick={onClickFind} placeHolder={!!t ? t('home:findAnItem') : 'keyword'} margin={false} />
                        </div>

                         : null
                }
            </div>
            <div className="flex-grow">
                <div className={isMobile ? "flex grid grid-cols-1 items-center justify-center" : "grid grid-cols-4 gap-3"}>
                    {
                        localList.map(item => (
                            <ItemComponent data={item} width={262} margin={true} key={item.name} baseUrl={baseUrl === '/services/' ? `${baseUrl}listing` : baseUrl === '/products/' ? `${baseUrl}${item.category.slug}` : `${baseUrl}`}  />
                        ))
                    }
                </div>
            </div>
        </Wrapper>
    )
}
