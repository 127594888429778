import DefaultLayout from "layouts/Default";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import BrandLayout from "layouts/BrandLayout";
import {wrapper} from "lib/store";
import React, {useEffect, useState} from "react";
import {getServiceCategories} from "lib/services/product";
import {useSelector, useDispatch} from "react-redux";
import ProductCard from "components/cards/ProductCard";
import MyList from "components/MyList";
import {getDealsByProductCategory} from "lib/services/deals";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button, Checkbox, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useRouter} from "next/router";
import {getBrand} from "lib/features/brandSlice";
import config from "../../../../data/config.json";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import {getPaginatedProductsThunk, getPaginatedServicesThunk} from "lib/features/productSlice";
import {toggleArray} from "../../../../utils/helper";
import ProductListing from "components/views/ProductListing";

const SORT_OPTIONS = config.product.sort
const PRICE_RANGES = config.product.priceRange

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
    const {
        query,locale,
    } = context
    let CATEGORIES = await getServiceCategories()
    CATEGORIES.map(c => ({name: c, slug: _.kebabCase(c)}))
    const allDeals = await getDealsByProductCategory()
    await store.dispatch(getBrand({slug: query.brandSlug, id: query.id}))
    const {
        page = 1,
        sort = {},
        deals = [],
        priceRanges = [],
        categories = [],
    } = query

    const filter = {
        deals,
        priceRanges,
        categories,
    }
    return {
        props: {
            allDeals,
            page,
            filter,
            sort,
            CATEGORIES,
            ...(await serverSideTranslations(locale, ['home', 'common']))

        }
    }
})

export default function Services(props) {
    const {
        page: _page,
        filter: _filter,
        sort: _sort,
        allDeals: deals,
        CATEGORIES
    } = props

    const brand = useSelector(state => state.brand.brand)
    const services = useSelector(state => state.product.services)
    const dispatch = useDispatch()
    const [filteredServices, setFilteredServices] = useState([])
    const [loaded, setLoaded] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        if (services && brand.services) {
            let tempFilteredServices
            if (brand.services.includes(',')) {
                tempFilteredServices = services.filter(item => brand.services.split(', ').includes(item.id))
            } else {
                tempFilteredServices = services.filter(item => brand.services === item.id)
            }

            setFilteredServices(tempFilteredServices)
        }
        setLoaded(true)

    }, [services])

    useEffect(() => {
        if (services.length === 1 && services[0].id === 0 ) {
            dispatch(getPaginatedServicesThunk({_page, _filter, _sort}))
        }
    }, [])

    useEffect(() => {
    }, [filteredServices])

    return (
        <Wrapper>
            <Container>
                {
                    loaded ?
                        <ProductListing
                            _filter={_filter} _page={_page} _sort={_sort}
                            products={filteredServices} deals={deals} brands={[]}
                            type={'Brand_Service'} categories={CATEGORIES}
                            baseUrl={`/services/listing/`}
                        /> : null
                }
            </Container>
        </Wrapper>
    )
}

Services.getLayout = function getLayout(page) {
    return (
        <DefaultLayout>
            <BrandLayout>
                {page}
            </BrandLayout>
        </DefaultLayout>
    )
}
