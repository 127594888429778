import * as React from 'react';
import { Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import {useEffect} from "react";


export default function MyBreadcrumbs({routeList, inverted, t}) {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator="›" sx={{ '.MuiBreadcrumbs-separator': { color: 'white' } }}>
            {
                routeList.map(route => (
                    <Link
                        underline="hover"
                        color={inverted ? route.isActive ? '#DDDDDD' : 'white' : route.isActive ? 'text.primary' : "inherit"}
                        href={route.href}
                        key={route.label}
                    >
                        {!!t ? t(`home:${route.label}`) : route.label}
                    </Link>
                ))
            }
        </Breadcrumbs>
    )
}
