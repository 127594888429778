import {DateTime} from "luxon";

export default function calcOpenNow(hours, timezone) {
  const zonedNow = DateTime.now().setZone(timezone)
  let tempHour = hours[zonedNow.weekdayLong.toLowerCase()]
  if (tempHour !== undefined) {
    const open = DateTime.fromFormat(tempHour.open, 'hh:mma', {zone: timezone})
    const close = DateTime.fromFormat(tempHour.close, 'hh:mma', {zone: timezone})

    return {
      isOpen: (open < zonedNow) && (zonedNow < close),
      openTime: open.toFormat('hh:mm a').toUpperCase(),
      closeTime: close.toFormat('hh:mm a').toUpperCase(),
    }
  } else {
    return {
      isOpen: false,
      openTime: '',
      closeTime: ''
    }
  }

}
