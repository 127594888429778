import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";

import Image from 'next/image'
import cn from "clsx";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";

export default function HeroSlider({
  id,
  slides,
  brightness,
  useNavigation=true,
  usePagination=true,
  className,
  ...swiperProps
                                   }) {
  return (
    <Wrapper className={cn(className, 'h-60vh]', 'py-0')}>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={useNavigation}
        pagination={usePagination ? {clickable:true} : false}
        id={id}
        className={cn('h-full')}
        {...swiperProps}
      >
        {
          slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <Image className={'object-cover'} style={{filter: `brightness(${brightness})`}}
                     layout={'fill'}
                     src={slide.image} alt=""/>
              <Wrapper className="relative h-full">
                <Container className="h-full">
                  {slide.content}
                </Container>
              </Wrapper>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Wrapper>
  )
}
